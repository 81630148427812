<template>
  <v-card style="max-width: 1000px" :loading="loading">
    <v-card-item class="pr-2">
      <template #title>
        <span>{{
          $t("admin.assets.lookupValues.parentConnections", { childCount: childValues?.length ?? 0, lookupValue: lookupValue.description })
        }}</span>
        <v-btn variant="text" :loading="loading" icon="refresh" @click="emit('refresh')" />
      </template>
    </v-card-item>

    <v-card-text>
      <v-table v-if="childValues?.length" :height="300" density="comfortable" class="bg-transparent overflow-auto p-5" fixed-header hover>
        <colgroup>
          <col class="w-0" />
          <col class="w-0" />
          <col class="w-0" />
        </colgroup>

        <thead>
          <tr>
            <th class="text-no-wrap">{{ $t("description") }}</th>
            <th class="text-no-wrap">{{ $t("organization") }}</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="childLookupValue in childValues" :key="childLookupValue._id">
            <td class="text-no-wrap">
              <nuxt-link class="text-primary" :to="`/admin/lookup-values/${childLookupValue._id}`">{{
                childLookupValue.description
              }}</nuxt-link>
            </td>
            <td class="text-no-wrap">{{ childLookupValue.organization?.description }}</td>
          </tr>
        </tbody>
      </v-table>
    </v-card-text>
  </v-card>
</template>

<script setup lang="ts">
const emit = defineEmits<{
  (e: "refresh"): void
}>()

const props = defineProps<{
  lookupValue: LookupValue
  childValues?: Array<LookupValue & { organization?: { _id: string; description: string } }>
  loading: boolean
}>()

const { lookupValue, loading, childValues } = toRefs(props)
</script>
