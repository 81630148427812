<template>
  <v-card style="max-width: 1000px">
    <v-form v-model="valid" :disabled="loading" @submit.prevent="onSubmit">
      <v-card-text>
        <v-autocomplete
          v-model="lookupValueData.lookupType"
          :loading="lookupTypesPending"
          :items="lookupTypes"
          :label="$t('admin.assets.lookupValues.field.lookupType') + ' *'"
          :rules="[(v) => !!v || $t('validation.required')]"
          item-title="description"
          item-value="_id"
          class="mb-2"
          clearable
          :messages="
            currentLookupType?.parent
              ? `${$t('admin.assets.lookupTypes.field.parent')}: ${lookupTypes.find((type) => type._id === currentLookupType?.parent)?.description}`
              : undefined
          "
        >
          <template #item="{ item, props: itemProps }">
            <v-list-item v-bind="itemProps">
              <template #title>{{ item.raw.description }}</template>
              <template #subtitle>
                <span v-if="item.raw.parent">
                  {{
                    `${$t("admin.assets.lookupTypes.field.parent")}: ${lookupTypes.find((type) => type._id === item.raw.parent)?.description}`
                  }}
                </span>
              </template>
            </v-list-item>
          </template>
        </v-autocomplete>

        <v-text-field
          v-model="lookupValueData.description"
          class="mb-2"
          :label="$t('admin.assets.lookupValues.field.description') + ' *'"
          :rules="[(v) => !!v || $t('validation.required')]"
        />

        <v-autocomplete
          v-model="lookupValueData.parent"
          :disabled="!lookupValues.length"
          :loading="pendingLookupValues"
          :items="lookupValues"
          :label="$t('admin.assets.lookupValues.field.parent')"
          item-title="description"
          item-value="_id"
          class="mb-2"
          clearable
        >
          <!-- Only show icon if selected value is invalid -->
          <template v-if="!currentLookupType?.parent && lookupValueData.parent" #prepend-inner>
            <v-icon
              icon="warning"
              color="error"
              :title="$t('admin.assets.lookupValues.invalidConnection', { lookupType: currentLookupType?.description })"
            />
          </template>

          <template #item="{ props: itemProps }">
            <v-list-item v-bind="itemProps">
              <template v-if="!currentLookupType?.parent && lookupValues.length" #subtitle>
                <v-icon icon="warning" color="error" />
                <span class="text-error">{{
                  $t("admin.assets.lookupValues.invalidConnection", { lookupType: currentLookupType?.description })
                }}</span>
              </template>
            </v-list-item>
          </template>
        </v-autocomplete>

        <v-text-field v-model="lookupValueData.price" type="number" class="mb-2" :label="$t('admin.assets.lookupValues.field.price')" />

        <v-text-field
          v-model="lookupValueData.priceYear"
          type="number"
          class="mb-2"
          :label="$t('admin.assets.lookupValues.field.priceYear')"
        />

        <v-autocomplete
          v-model="lookupValueData.organization"
          :items="customers"
          class="mb-2"
          :label="$t('admin.assets.lookupValues.field.organization')"
          item-title="description"
          item-value="_id"
          clearable
        />

        <v-divider />

        <file-list
          v-model:files="files"
          @add="(newFiles: Array<FileData>) => (files = [...files, ...newFiles])"
          @delete="(fileId: string) => deleteFile(fileId)"
        />
      </v-card-text>

      <v-card-actions class="justify-end">
        <v-dialog v-if="lookupValue" width="600">
          <template #activator="{ props: dialogProps }">
            <v-btn v-bind="dialogProps" color="error" :disabled="!linkedAssets.length">{{ $t("replace") }}</v-btn>
          </template>

          <template #default="{ isActive }">
            <lookup-value-replace-form
              :lookup-value="lookupValue"
              @cancel="
                () => {
                  isActive.value = false
                }
              "
              @submit="
                () => {
                  isActive.value = false
                  refreshLinkedAssets()
                  emit('replace')
                }
              "
            />
          </template>
        </v-dialog>

        <v-dialog v-if="lookupValue" width="600">
          <template #activator="{ props: dialogProps }">
            <v-btn v-bind="dialogProps" color="error">{{ $t("delete") }}</v-btn>
          </template>

          <template #default="{ isActive }">
            <lookup-value-delete-confirmation
              :lookup-value="lookupValue"
              @cancel="
                () => {
                  isActive.value = false
                }
              "
              @submit="
                () => {
                  isActive.value = false
                  emit('delete')
                }
              "
            />
          </template>
        </v-dialog>

        <v-btn color="primary" @click="emit('cancel')">{{ $t("cancel") }}</v-btn>
        <v-btn color="primary" type="submit" :loading="loading">{{ $t("save") }}</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>

  <lookup-value-usage-card
    v-if="lookupValue"
    class="mt-5"
    :lookup-value="lookupValue"
    :loading="pendingChildValues"
    :child-values="childLookupValues"
    @refresh="refreshChildValues"
  />

  <lookup-value-asset-usage-card
    v-if="lookupValue"
    class="mt-5"
    :lookup-value="lookupValue"
    :loading="pendingLinkedAssets"
    :assets="linkedAssets"
    @refresh="refreshLinkedAssets"
  />
</template>

<script setup lang="ts">
import cloneDeep from "lodash-es/cloneDeep"
import isEqual from "lodash-es/isEqual"

const emit = defineEmits<{
  (e: "submit", lookupValue: LookupValue): void
  (e: "cancel" | "delete" | "replace"): void
}>()

const props = defineProps<{
  lookupValue?: LookupValue | null
}>()
const { lookupValue } = toRefs(props)

const $i18n = useI18n()
const lookupValueStore = useLookupValueStore()
const mainStore = useMainStore()
const fileStore = useFileStore()
const organizationStore = useOrganizationStore()

const { customers } = storeToRefs(organizationStore)
const files = ref<Array<FileData>>([])
const valid = ref(false)
const loading = ref(false)

const { data: lookupTypes, pending: lookupTypesPending } = useLazyAsyncData(
  "lookup-types",
  async () => {
    return (await lookupValueStore.getLookupTypesByPage({ pagination: false })).docs
  },
  { default: () => [] },
)

const lookupValueData = ref<Partial<LookupValue>>({
  lookupType: lookupValue?.value?.lookupType,
  description: lookupValue?.value?.description,
  parent: lookupValue?.value?.parent,
  price: lookupValue?.value?.price,
  priceYear: lookupValue?.value?.priceYear,
})

const currentLookupType = computed(() => lookupTypes.value?.find((lt) => lt._id === lookupValueData.value.lookupType))

const { data: lookupValues, pending: pendingLookupValues } = useLazyAsyncData(
  "lookup-values-by-lookup-type",
  async () => {
    if (!currentLookupType.value?.parent) {
      // No parent configured for the lookupType, but there is a parent value set
      if (lookupValueData.value.parent) {
        const response = await lookupValueStore.getLookupValueById(lookupValueData.value.parent)
        return [response]
      }

      return []
    }

    const response = await lookupValueStore.getLookupValuesByPage({
      pagination: false,
      query: JSON.stringify({ lookupType: currentLookupType.value.parent }),
    })

    return response.docs
  },
  {
    default: () => [],
    watch: [currentLookupType],
  },
)

const {
  data: childLookupValues,
  pending: pendingChildValues,
  refresh: refreshChildValues,
} = useLazyAsyncData(
  `lookup-values-by-parent-${lookupValue.value?._id}`,
  async () => {
    if (!lookupValue.value) {
      return []
    }

    const populate = [
      {
        path: "organization",
        model: "Organization",
        select: ["description"],
      },
    ]

    // Get lookup values that use this value as a parent.
    const result = await lookupValueStore.getLookupValuesByPage({ pagination: false, query: { parent: lookupValue.value?._id }, populate })
    const docs = result.docs as unknown as Array<LookupValue & { organization?: { _id: string; description: string } }>

    return docs
  },
  {
    default: () => [] as Array<LookupValue & { organization?: { _id: string; description: string } }>,
    watch: [lookupValue],
  },
)

const {
  data: linkedAssets,
  pending: pendingLinkedAssets,
  refresh: refreshLinkedAssets,
} = useLazyAsyncData(
  `linked-assets-${lookupValue.value?._id}`,
  async () => {
    if (!lookupValue.value) {
      return []
    }

    return await lookupValueStore.getAssetsByLookupValue(lookupValue.value._id)
  },
  {
    default: () => [] as Array<LookupValueAsset>,
    watch: [lookupValue],
  },
)

useLazyAsyncData("organizations", async () => organizationStore.getAllOrganizations({ pagination: false }), {
  default: () => [] as Array<Organization>,
})

const deleteFile = (fileId: string) => {
  useExplicitSave(mainStore.notify, $i18n, async () => {
    if (!valid.value) {
      return
    }

    loading.value = true

    try {
      await fileStore.deleteFile(fileId)
      files.value = files.value.filter((f) => f._id !== fileId)
    } finally {
      loading.value = false
    }
  })
}

const onSubmit = async () => {
  if (!valid.value) {
    return
  }

  useExplicitSave(mainStore.notify, $i18n, async () => {
    if (!valid.value) {
      return
    }

    loading.value = true

    const data = cloneDeep(lookupValueData.value)
    data.files = files.value.map((f) => f._id)

    // Prevent sending empty string instead of number
    if (!data.price) data.price = undefined
    if (!data.priceYear) data.priceYear = undefined

    try {
      const response = lookupValue?.value
        ? await lookupValueStore.updateLookupValue(lookupValue.value._id, data)
        : await lookupValueStore.createLookupValue(data)
      emit("submit", response)
    } finally {
      loading.value = false
    }
  })
}

watch(
  () => lookupValue?.value,
  async () => {
    lookupValueData.value = {
      lookupType: lookupValue?.value?.lookupType,
      description: lookupValue?.value?.description,
      parent: lookupValue?.value?.parent,
      price: lookupValue?.value?.price,
      priceYear: lookupValue?.value?.priceYear,
      organization: lookupValue?.value?.organization,
    }

    files.value = await Promise.all(lookupValue?.value?.files.map((fileId) => fileStore.getFileById(fileId)) ?? [])
  },
  { immediate: true },
)

watch(files, async () => {
  if (!lookupValue?.value) {
    return
  }

  // Prevents saving changes while initializing
  if (
    isEqual(
      files.value.map((file) => file._id),
      lookupValue?.value?.files,
    )
  ) {
    return
  }

  try {
    await lookupValueStore.updateFiles(
      lookupValue.value._id,
      files.value.map((f) => f._id),
    )
  } catch {
    mainStore.notify({ type: "error", title: $i18n.t("admin.assets.lookupValues.fileUploadError") })
  }
})
</script>
